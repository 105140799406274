// USA
export const locale = {
    STAGING_ENVIRONMENT: "Staging-Umgebung",
    DEVELOPMENT_ENVIRONMENT: "Development-Umgebung",
    YES: "Ja",
    NO: "Nein",
    ACTIONS: "Aktionen",
    EDIT: "Bearbeiten",
    SAVE: "Speichern",
    CANCEL: "Abbrechen",
    DELETE: "Löschen",
    SUBMIT: "Absenden",
    CLOSE: "Schließen",
    VIEW: "Ansicht",
    ADD: "Hinzufügen",
    REMOVE: "Entfernen",
    IMPRINT: "Impressum",
    PRIVACY: "Datenschutz",
    CONTACT: "Kontakt",
    ADMINISTRATION: "Administration",
    NOT_FOUND: "Diese Seite konnte nicht gefunden werden",
    TOGGLE_MENU: "Menü Ein-/Ausklappen",
    FAVORITE: "Standard",
    AUTH: {
        WELCOME: "Willkommen am Xiphoo Portal",
        CHALLENGE_NEW_PASSWORD: "Passwort setzen",
        CHALLENGE_NEW_PASSWORD_SUBHEADER:
            "Bei ihrer ersten Anmeldung müssen Sie ein neues Passwort setzen",
        FORGOT: "Passwort vergessen?",
        FORGOT_SUBHEADER:
            "Geben Sie ihre E-Mail Adresse ein um Ihr Passwort zurückzusetzen",
        RESET: "Passwort zurücksetzen",
        NEW_PASSWORD: "Neues Passwort",
        NEW_PASSWORD_CONFIRM: "Neues Passwort (Bestätigung)",
        LOGIN: "Anmelden",
        LOGOUT: "Abmelden",
        LOGGED_IN_AS: "Angemeldet als",
        EMAIL: "E-Mail",
        PASSWORD: "Passwort",
        ADMIN: "Administrator",
        LOGIN_FAIL: "Anmeldung fehlgeschlagen. Sind Ihre Daten korrekt?",
        FORGOT_SUCCESS:
            "Die Anleitung zum Zurücksetzen Ihres Passworts wurde an Ihre E-Mail Adresse verschickt, falls diese existiert.",
        FORGOT_FAIL:
            "Anfrage zum Zurücksetzen Ihres Passworts fehlgeschlagen. Bitte versuchen Sie es später nocheinmal.",
        RESET_SUCCESS:
            "Ihr neues Passwort wurde erfolgreich gesetzt. Sie können sich nun anmelden.",
        RESET_FAIL:
            "Ihr neues Passwort konnte nicht gesetzt werden. Bitte stellen Sie eien neue Anfrage zum Zurücksetzen.",
    },
    IMPORTER: {
        IMPORTER: "Massenimport",
        START_IMPORT: "Import starten",
        NEW_IMPORT: "Neuer Import",
        INFO_FORMAT:
            "Die Datei muss entweder im Xiphoo CSV oder im Xiphoo JSON Format sein.",
        INFO_DUPLICATES:
            "Produkt SKUs und EANs/UPCs müssen einzigartig sein. Wenn ein Produkt mit einer in der Datei referenzierten SKU oder einem EAN/UPC bereits existiert (in der Datenbank oder Datei), wird der Import fehlschlagen. Partielle Imports oder Aktualisierungen werden nicht unterstützt.",
        INFO_MINIMAL_REQUIREMENTS:
            "Alle Produkte müssen zumindest eine Farbe mit Größe und Bestandteil (mindestens ein Material) haben.",
        BROWSE: "Durchsuchen",
        CHOOSE_FILE: "Datei auswählen (Xiphoo CSV oder Xiphoo JSON)",
        DROP_FILE: "Datei ablegen (Xiphoo CSV oder Xiphoo JSON)",
        RUNNING: "Import läuft",
        STATUS_PARSING: "Datei wird verarbeitet",
        STATUS_SERVER: "Daten werden am Server verarbeitet",
        STATUS_IMAGES: "Bilder werden hochgeladen",
        FAIL: "Import fehlgeschlagen",
        FAIL_ROW: "Zeile",
        FAIL_FILE: "Datei konnte nicht geladen werden",
        FAIL_FILE_FORMAT: "Ungültiges Dateiformat",
        FAIL_FILE_JSON: "Ungültige JSON-Datei",
        FAIL_FILE_CSV: "Ungültige CSV-Datei",
        FAIL_FILE_CSV_HEADERS: "CSV-Datei nicht im Xiphoo Format",
        FAIL_INVALID_VALUE: "Ungültiger Wert für Feld",
        FAIL_INVALID_VALUE_CSV: "Ungültiger Wert für Spalte",
        FAIL_MISSING_VALUE: "Fehlender Wert für Feld",
        FAIL_DUPLICATE_VALUE: "Doppelter Wert für Feld",
        FAIL_IMAGE: "Bild konnte nicht hochgeladen werden",
        SUCCESS: "Import erfolgreich",
        SUCCESS_PRODUCT_COUNT:
            "Keine Produkte importiert | 1 Produkt importiert | {n} Produkte importiert",
        SUCCESS_IMAGE_COUNT:
            "Keine Bilder hochgeladen | {count} von 1 Bild hochgeladen | {count} von {n} Bildern hochgeladen",
    },
    REPORTS: {
        REPORTS: "Auswertungen",
        STATISTICS: "Statistiken",
        BY_PRODUCT: "nach Produkt",
        BY_CATEGORY: "nach Kategorie",
        BY_GENDER: "nach Geschlecht",
        FILTER: "Filtern ...",
        UNKNOWN: "Unbekannt",
        EXPORT: "Download CSV Datei",
    },
    ABOUT: {
        ABOUT: "Stammdaten",
        EDIT: "Stammdaten bearbeiten",
    },
    BRAND: {
        BRAND: "Marke",
        BRANDS: "Marken",
        VIEW: "Markenansicht",
        EDIT: "Marke bearbeiten",
        NEW: "Neue Marke",
        NOT_FOUND: "Marke nicht gefunden",
        SHOW_COUNT:
            "Keine Marken | Zeige 1 Marke | Zeige {n} von {total} Marken",
        DELETE_TITLE: "Marke '{name}' löschen?",
        DELETE_TEXT:
            "Wollen Sie diese Marke wirklich löschen? Dies kann nicht rückgängig gemacht werden.",
        DELETE_SUCCESS: "Marke '{name}' erfolgreich gelöscht.",
        DELETE_FAILURE:
            "Marke '{name}' konnte nicht gelöscht werden. Bitte entfernen sie zuerst alle verbundenen Produkte und Benutzer.",
        LOGO: "Logo",
        LOGO_FILETYPES: "Erlaubte Dateitypen: png, jpg, jpeg",
        NAME: "Name",
        TAX_ID: "Steuernummer",
        STREET: "Straße",
        CITY: "Stadt",
        ZIP: "PLZ",
        COUNTRY: "Land",
        PRODUCT_PAGE: "Produktseitenfunktion",
        NO_LOGO: "Keines",
    },
    USER: {
        USER: "Benutzer",
        USERS: "Benutzer",
        VIEW: "Benutzeransicht",
        EDIT: "Benutzer bearbeiten",
        NEW: "Neuer Benutzer",
        NOT_FOUND: "Benutzer nicht gefunden",
        SHOW_COUNT:
            "Keine Benutzer | Zeige 1 Benutzer | Zeige {n} von {total} Benutzern",
        DELETE_TITLE: "Benutzer '{name}' löschen?",
        DELETE_TEXT:
            "Wollen Sie diesen Benutzer wirklich löschen? Dies kann nicht rückgängig gemacht werden.",
        DELETE_SUCCESS: "Benutzer '{name}' erfolgreich gelöscht.",
        DELETE_FAILURE: "Benutzer '{name}' konnte nicht gelöscht werden.",
        EMAIL: "E-Mail",
        TITLE: "Anrede",
        FIRST_NAME: "Vorname",
        LAST_NAME: "Nachname",
        FUNCTION: "Funktion",
    },
    PRODUCT: {
        PRODUCT: "Produkt",
        PRODUCTS: "Produkte",
        MANAGEMENT: "Produktverwaltung",
        VIEW: "Produktansicht",
        EDIT: "Produkt bearbeiten",
        NEW: "Neues Produkt",
        NOT_FOUND: "Produkt nicht gefunden",
        SHOW_COUNT:
            "Keine Produkte | Zeige 1 Produkt | Zeige {n} von {total} Produkten",
        DELETE_TITLE: "Produkt '{name}' löschen?",
        DELETE_TEXT:
            "Wollen Sie dieses Produkt wirklich löschen? Dies kann nicht rückgängig gemacht werden.",
        DELETE_SUCCESS: "Produkt '{name}' erfolgreich gelöscht.",
        DELETE_FAILURE:
            "Produkt '{name}' konnte nicht gelöscht werden. Bitte entfernen Sie zuerst alle verbundenen Farben.",
        NAME: "Name",
        BRAND_DIVISION: "Markensparte",
        SKU: "SKU",
        CATEGORY: "Kategorie",
        SUB_CATEGORY: "Subkategorie",
        FURTHER_SUB_CATEGORY: "Weitere Subkategorie",
        GENDER: "Geschlecht",
        FEDAS_PCK: "FEDAS PCK",
        APP_DESCRIPTION: "App Beschreibung",
        INTERNAL_DESCRIPTION: "Interne Beschreibung",
        FURTHER_INTERNAL_DESCRIPTION: "Weitere interne Beschreibung",
        QRCODE: "QR-Code Genaration",
        QRCHARGE: "QR-Code Listen",
        SHOW_COUNT2:
            "Keine QR-CODE Listen | Zeige 1 QR-Code Liste | Zeige {n} von {total} QR-Code Listen",
        QR_NAME: "Name",
        QR_ORDER_NUMBER: "Bestellnummer",
        QRC_COMMENT: "Kommentar",
        QR_AMOUNT: "Anzahl der Codes",
        QR_EXPORT: "AKTION",
        QR_CREATE: "Erstellt",
        QR_USED: "Zugeordnet",
        QR_MANUFACTURER: "Hersteller",
        EXPORT: "Produkt Export",
        PRODUCT_PAGE: "Produktseite",
        PRODUCT_PAGE_CUSTOM: "Eigene URL",
        PRODUCT_PAGE_XIPHOO: "XIPHOO Website Builder",
        PRODUCT_PAGE_LABEL: "Produkt URL",
        PRODUCT_PAGE_EDIT: "Produktseite bearbeiten",
    },
    COLOR: {
        COLOR: "Farbe",
        COLORS: "Farben",
        VIEW: "Farbansicht",
        EDIT: "Farbe bearbeiten",
        NEW: "Neue Farbe",
        NOT_FOUND: "Farbe nicht gefunden",
        SHOW_COUNT:
            "Keine Farben | Zeige 1 Farbe | Zeige {n} von {total} Farben",
        DELETE_TITLE: "Farbe '{name}' löschen?",
        DELETE_TEXT:
            "Wollen Sie diese Farbe wirklich löschen? Dies kann nicht rückgängig gemacht werden.",
        DELETE_SUCCESS: "Farbe '{name}' erfolgreich gelöscht.",
        DELETE_FAILURE:
            "Farbe '{name}' konnte nicht gelöscht werden. Bitte entfernen Sie zuerst alle verbundenen Größen.",
        COMPONENTS_AND_LAUNDRY_INSTRUCTIONS: "Bestandteile & Pflegeanweisungen",
        IMAGE: "Bild",
        IMAGE_FILETYPES: "Erlaubte Dateitypen: png, jpg, jpeg",
        NAME: "Name",
        WEIGHT: "Gewicht",
        YEAR: "Jahr",
        ORIGIN: "Herkunftsland",
        CUSTOMS_NR: "Zolltarifnummer",
        URL: "App URL",
        NO_IMAGE: "Keines",
        CODE: "Code",
        TAGS: "Tags",
        SCANS: "Scans",
        IMPORT_PRODUCT_PAGE_LINK: "XIPHOO Produktseitenlink hinzufügen",
    },
    SIZE: {
        SIZE: "Größe",
        SIZES: "Größen",
        VIEW: "Größenansicht",
        EDIT: "Größe bearbeiten",
        NEW: "Neue Größe",
        NOT_FOUND: "Größe nicht gefunden",
        SHOW_COUNT:
            "Keine Größen | Zeige 1 Größe | Zeige {n} von {total} Größen",
        DELETE_TITLE: "Größe '{name}' löschen?",
        DELETE_TEXT:
            "Wollen Sie diese Größe wirklich löschen? Dies kann nicht rückgängig gemacht werden.",
        DELETE_SUCCESS: "Größe '{name}' erfolgreich gelöscht.",
        DELETE_FAILURE:
            "Größe '{name}' konnte nicht gelöscht werden. Bitte entfernen Sie zuerst alle verbundenen Tags.",
        NAME: "Name",
        EAN: "EAN",
        UPC: "UPC",
        RRP: "UVP",
        SCANS: "Scans",
        TAGS: "Tags",
    },
    LAUNDRY: {
        TITLE: "Pflegeanweisungen",
        WASHING: "Waschen",
        BLEACHING: "Bleichen",
        DRYING: "Trocknen",
        IRONING: "Bügeln",
        PROFESSIONAL_CLEANING: "Professionelles Reinigen",
    },
    COMPONENT: {
        COMPONENT: "Bestandteil",
        COMPONENTS: "Bestandteile",
        VIEW: "Bestandteilansicht",
        EDIT: "Bestandteil bearbeiten",
        NEW: "Neuer Bestandteil",
        NOT_FOUND: "Bestandteil nicht gefunden",
        SHOW_COUNT:
            "Keine Bestandteile | Zeige 1 Bestandteil | Zeige {n} von {total} Bestandteilen",
        DELETE_TITLE: "Bestandteil '{name}' löschen?",
        DELETE_TEXT:
            "Wollen Sie diesen Bestandteil wirklich löschen? Dies kann nicht rückgängig gemacht werden.",
        DELETE_SUCCESS: "Bestandteil '{name}' erfolgreich gelöscht.",
        DELETE_FAILURE: "Bestandteil '{name}' konnte nicht gelöscht werden.",
        NAME: "Name",
        MATERIAL: "Material",
        MATERIALS: "Materialien",
        AMOUNT: "Anteil",
    },
    TAG: {
        TAG: "Tag",
        TAGS: "Tags",
        NOT_FOUND: "Tag nicht gefunden",
        SHOW_COUNT: "Keine Tags | Zeige 1 Tag | Zeige {n} von {total} Tags",
        DELETE_TITLE: "Tag '{name}' löschen?",
        DELETE_TEXT:
            "Wollen Sie diesen Tag wirklich löschen? Dies kann nicht rückgängig gemacht werden.",
        DELETE_SUCCESS: "Tag '{name}' erfolgreich gelöscht.",
        DELETE_FAILURE: "Tag '{name}' konnte nicht gelöscht werden.",
        DETAILS: "Tagdetails",
        SCAN_DETAILS: "Scandetails",
        UID: "UID",
        SCANS: "Scans",
        LAST_SCAN: "Letzter Scan",
        LAST_SCAN_NEVER: "Nie",
        LAST_LOCATION: "Letzter Ort",
        LOCATION_UNKNOWN: "Unbekannt",
        HISTORY: {
            HISTORY: "Scanverlauf",
            SHOW_COUNT:
                "Keine Scans | Zeige 1 Scan | Zeige {n} von {total} Scans",
            TIME: "Zeit",
            SECURE_SCAN: "Sicherer Scan",
            LATITUDE: "Breitengrad",
            LONGITUDE: "Längengrad",
        },
        STATUS: {
            STATUS: "Status",
            REGISTRATION_PENDING: "Registrierung ausständig",
            SCAN_PENDING: "Scan ausständig",
            OPERATIONAL: "Betriebsbereit",
            INVALID: "Ungültig",
        },
        SELLER: "Verkäufer",
        LIFECYCLE: {
            LIFECYCLE: "Lebenszyklus",
            PRESALE: "Presale",
            POSTSALE: "Postsale",
        },
    },
    SEARCH: {
        SEARCH: "Suche",
        PLACEHOLDER: "Produktname, SKU, EAN, UPC ...",
        NO_RESULTS: "Keine Ergebnisse",
    },
    VALIDATION: {
        REQUIRED: "{name} ist ein Pflichtfeld",
        ASCII: "{name} darf nur ASCII-Zeichen beinhalten",
        DIGITS: "{name} darf nur Ziffern beinhalten",
        LENGTH_EXACT: "{name} muss exakt {length} Zeichen lang sein",
        LENGTH_MAX: "{name} darf maximal {length} Zeichen lang sein",
        WEB_URL: "{name} ist keine gültige Web-URL",
        EAN: "{name} ist kein gültiger EAN-8 oder EAN-13",
        PRICE: "{name} ist kein gültiger Preis",
        EMAIL: "{name} ist keine gültige E-Mail Adresse",
        PASSWORD:
            "{name} muss mindestens 12 Zeichen lang sein und mindestens einen Großbuchstaben, eine Kleinbuchstaben und eine Ziffer beinhalten",
        IDENTICAL: "{name} und {name_compare} müssen identisch sein",
        EXISTS: "{name} existiert bereits",
        PERCENTAGE: "{name} ist keine Prozentangabe",
        LOW: "{name} muss größer als 0 sein",
    },
    QRCHARGE: {
        NEW: "Neue QR-Code Liste",
        ORDER_NUMBER: "Bestellnummer",
        COMMENT: "Kommentar",
        AMOUNT: "Anzahl",
        EXPORTCSV: "Download CSV Datei",
        EDIT: "Bearbeiten QR-Code Liste",
        TITLE: "QR-Codes",
        NAME: "Chargenname",
        MANUFACTURER: "Hersteller",
        NOMANUFACTURER: "kein Hersteller",
        DELETE_TITLE: "QR-Liste löschen",
        DELETE_TEXT: "Wollen Sie die QR-Liste wirklich löschen?",
    },
    MANUFACTURER: {
        MANUFACTURER: "HERSTELLER",
        MANUFACTURERLIST: "Hersteller Liste",
        NAME: "Name",
        STREET: "Straße",
        CITY: "Stadt",
        ZIP: "PLZ",
        COUNTRY: "Land",
        CODE: "Code",
        COMMENT: "Kommentar",
        NEW: "Neu anlegen",
        SHOW_COUNT: "Anzahl",
        VIEW: "Hersteller",
        EDIT: "Hersteller editieren",
        DELETE_TITLE: "Hersteller löschen",
        DELETE_TEXT: "Wollen Sie den Hersteller wirklich löschen?",
    },
    PRODUCT_PAGE: {
        TITLE: "Produktseiteneditor",
        SUBTITLE: "Seitenbausteine",
        PREVIEW: "Vorschauansicht",
        EDIT: "Seitenbaustein bearbeiten",
        VIEW: "Vorschauansicht",
        NAME: "Name des Elements / Titel",
        DESCRIPTION: "Beschreibung",
        NOT_FOUND: "Produktseite nicht gefunden",
        COLOR_OPTION_NAME: "Produkt Informationen",
        BRAND_LOGO_NAME: "Brand Logo",
        BACKGROUND_NAME: "Hintergrund",
        MODEL: "Modell",
        COLOR: "Farbe",
        SIZE: "Größe",
        CANCEL: "Abbrechen",
        SAVE: "Speichern",
        FONT: "Schriftart",
        FONT_PREVIEW: "Textvorschau",
        NEW_COMPONENT: "Neue Komponente hinzufügen",
        NEW_INFORMATION_COMPONENT: "Produktinformationen hinzufügen",
        NEW_BRAND_LOGO: "Brand Logo hinzufügen",
        NEW_BACKGROUND: "Hintergrund hinzufügen",
        BACKGROUND_COLOR: "Hintergrundfarbe (HEX-Wert)",
        POSITIONING: "Positionierung",
        TEXTPOSITIONING: "Textpositionierung",
        TEXT_COLOR: "Textfarbe (HEX-Wert)",
        BORDER: "Rahmen",
        BORDER_COLOR: "Rahmenfarbe (HEX-Wert)",
        WASHING_INSTRUCTION: "Waschanleitung",
    },
    PRODUCT_PAGES: {
        TITLE: "Produktseiten",
        NEW: "Neue Produktseite",
        NAME: "Name",
        PRODUCTS: "Verknüpfte Produkte",
        NO_PRODUCTS: "Keine verknüpften Produkte vorhanden",
        EXPAND: "Verknüpfte Produkte",
    },
    PRODUCT_PAGE_META: {
        TITLE: "Produktseiteninformationen",
        NEW: "Neue Produktseite",
        EDIT: "Produktseite bearbeiten",
        VIEW: "Produktseitenansicht",
        NOT_FOUND: "Produktseite nicht gefunden",
        DELETE_TITLE: "Produktseite '{name}' löschen?",
        DELETE_TEXT:
            "Wollen Sie diese Produktseite wirklich löschen? Dies kann nicht rückgängig gemacht werden.",
        DELETE_SUCCESS: "Produktseite '{name}' erfolgreich gelöscht.",
        DELETE_FAILURE: "Produktseite '{name}' konnte nicht gelöscht werden.",
        NAME: "Name",
        PRODUCT_PAGE: "Produktseite bearbeiten",
        PRODUCT_RELATIONS: "Gemeinsame Produktseite",
        NO_PRODUCTS:
            "Es wurden keine Produkte für diese Produktseite ausgewählt!",
        ADD_PRODUCT: "Produkt für die Produktseite hinzufügen",
        SELECT_PRODUCT: "Produkt auswählen...",
        ADD_PRODUCT_BUTTON: "Produkte hinzufügen",
    },
}
