import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/products",
            component: () => import("@/view/layout/Layout.vue"),
            props: { foo: "2020-01-01" },
            children: [
                {
                    path: "/products",
                    name: "products",
                    component: () => import("@/view/pages/Products.vue"),
                },
                {
                    path: "/users",
                    name: "users",
                    component: () => import("@/view/pages/Users.vue"),
                },
                {
                    path: "/user/:sub",
                    name: "user",
                    component: () => import("@/view/pages/User.vue"),
                },
                {
                    path: "/about",
                    name: "about",
                    component: () => import("@/view/pages/Brand.vue"),
                },
                {
                    path: "/brands",
                    name: "brands",
                    component: () => import("@/view/pages/Brands.vue"),
                },
                {
                    path: "/brand/:id",
                    name: "brand",
                    component: () => import("@/view/pages/Brand.vue"),
                },
                {
                    path: "/product/:id",
                    name: "product",
                    component: () => import("@/view/pages/Product.vue"),
                },
                {
                    path: "/product_pages/:id/product_page",
                    name: "product_page",
                    component: () => import("@/view/pages/ProductPage.vue"),
                },
                {
                    path: "/product_pages",
                    name: "product_pages",
                    component: () => import("@/view/pages/ProductPages.vue"),
                },
                {
                    path: "/product_pages/:id",
                    name: "product_page_meta",
                    component: () => import("@/view/pages/ProductPageMeta.vue"),
                },
                {
                    path: "/color/:id",
                    name: "color",
                    component: () => import("@/view/pages/Color.vue"),
                },
                {
                    path: "/size/:id",
                    name: "size",
                    component: () => import("@/view/pages/Size.vue"),
                },
                {
                    path: "/component/:id",
                    name: "componentmaterials",
                    component: () => import("@/view/pages/Component.vue"),
                },
                {
                    path: "/tag/:uid",
                    name: "tag",
                    component: () => import("@/view/pages/Tag.vue"),
                },
                {
                    path: "/statistics",
                    name: "productsstatistics",
                    component: () =>
                        import("@/view/pages/ProductsStatistics.vue"),
                    props: (route) => ({ foo: route.query.foo }),
                },

                {
                    path: "/importer",
                    name: "massimport",
                    component: () => import("@/view/pages/MassImport.vue"),
                },

                {
                    path: "/qrcharge",
                    name: "qrcharge",
                    component: () => import("@/view/pages/qrcodes.vue"),
                },

                {
                    path: "/qrcharge/:id",
                    name: "qrcharges",
                    component: () => import("@/view/pages/qrcharge.vue"),
                },

                {
                    path: "/qrcodes/:id",
                    name: "qrcodes",
                    component: () => import("@/view/pages/qrcodes_details.vue"),
                },
                {
                    path: "/manufacturers",
                    name: "manufacturers",
                    component: () => import("@/view/pages/Manufacturer.vue"),
                },
                {
                    path: "/manufacturer/:id",
                    name: "manufacturer",
                    component: () =>
                        import("@/view/pages/Manufacturer_detail.vue"),
                },
            ],
        },
        {
            path: "/",
            component: () => import("@/view/pages/Login.vue"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/Login.vue"),
                },
            ],
        },
        {
            path: "*",
            redirect: "/404",
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/Error.vue"),
        },
    ],
})
