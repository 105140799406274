// USA
export const locale = {
    STAGING_ENVIRONMENT: "Staging environment",
    DEVELOPMENT_ENVIRONMENT: "Development environment",
    YES: "Yes",
    NO: "No",
    ACTIONS: "Actions",
    EDIT: "Edit",
    SAVE: "Save",
    CANCEL: "Cancel",
    DELETE: "Delete",
    SUBMIT: "Submit",
    CLOSE: "Close",
    VIEW: "View",
    ADD: "Add",
    REMOVE: "Remove",
    IMPRINT: "Imprint",
    PRIVACY: "Privacy Policy",
    CONTACT: "Contact",
    ADMINISTRATION: "Administration",
    NOT_FOUND: "This page could not be found",
    TOGGLE_MENU: "Collapse/expand menu",
    FAVORITE: "Standard",
    AUTH: {
        WELCOME: "Welcome to the Xiphoo Portal",
        CHALLENGE_NEW_PASSWORD: "Set password",
        CHALLENGE_NEW_PASSWORD_SUBHEADER:
            "You need to set a new password when first signing in",
        FORGOT: "Forgot password?",
        FORGOT_SUBHEADER: "Enter your email to reset your password",
        RESET: "Reset password",
        NEW_PASSWORD: "New password",
        NEW_PASSWORD_CONFIRM: "New password (confirmation)",
        LOGIN: "Sign in",
        LOGOUT: "Sign out",
        LOGGED_IN_AS: "Signed in as",
        EMAIL: "Email",
        PASSWORD: "Password",
        ADMIN: "Administrator",
        LOGIN_FAIL: "Login failed. Is your data correct?",
        FORGOT_SUCCESS:
            "Instructions to reset your password have been sent to your email, if it exists.",
        FORGOT_FAIL: "Reset request failed. Please try again later.",
        RESET_SUCCESS:
            "Your new password has been successfully set. You can now sign in.",
        RESET_FAIL:
            "Your new password could not be set. Please submit a new password reset request.",
    },
    IMPORTER: {
        IMPORTER: "Mass Import",
        START_IMPORT: "Start Import",
        NEW_IMPORT: "New Import",
        INFO_FORMAT:
            "The file has to be either in Xiphoo CSV or Xiphoo JSON format.",
        INFO_DUPLICATES:
            "Product SKUs and EANs/UPCs must be unique. If a product with a SKU or EAN/UPC referenced in the import file already exists (in the database or file), the import will fail. Partial imports or updates are not supported.",
        INFO_MINIMAL_REQUIREMENTS:
            "All products must have at least one color with size and component (at least one material).",
        BROWSE: "Browse",
        CHOOSE_FILE: "Choose file (Xiphoo CSV or Xiphoo JSON)",
        DROP_FILE: "Drop file (Xiphoo CSV or Xiphoo JSON)",
        RUNNING: "Import running",
        STATUS_PARSING: "Processing file",
        STATUS_SERVER: "Processing data on server",
        STATUS_IMAGES: "Uploading images",
        FAIL: "Import failed",
        FAIL_ROW: "Row",
        FAIL_FILE: "Could not load file",
        FAIL_FILE_FORMAT: "Invalid file format",
        FAIL_FILE_JSON: "Invalid JSON file",
        FAIL_FILE_CSV: "Invalid CSV file",
        FAIL_FILE_CSV_HEADERS: "CSV file not in Xiphoo format",
        FAIL_INVALID_VALUE: "Invalid value for field",
        FAIL_INVALID_VALUE_CSV: "Invalid value for column",
        FAIL_MISSING_VALUE: "Missing value for field",
        FAIL_DUPLICATE_VALUE: "Duplicate value for field",
        FAIL_IMAGE: "Failed to upload image",
        SUCCESS: "Import successful",
        SUCCESS_PRODUCT_COUNT:
            "No products imported | 1 product imported | {n} products imported",
        SUCCESS_IMAGE_COUNT:
            "No images uploaded | {count} of 1 image uploaded | {count} of {n} images uploaded",
    },
    REPORTS: {
        REPORTS: "Reports",
        STATISTICS: "Statistics",
        BY_PRODUCT: "by product",
        BY_CATEGORY: "by category",
        BY_GENDER: "by gender",
        FILTER: "Filter ...",
        UNKNOWN: "Unknown",
        EXPORT: "Download CSV file",
    },
    ABOUT: {
        ABOUT: "About",
        EDIT: "Edit About",
    },
    BRAND: {
        BRAND: "Brand",
        BRANDS: "Brands",
        VIEW: "View Brand",
        EDIT: "Edit Brand",
        NEW: "New Brand",
        NOT_FOUND: "Brand not found",
        SHOW_COUNT:
            "No brands | Showing 1 brand | Showing {n} of {total} brands",
        DELETE_TITLE: "Delete brand '{name}'?",
        DELETE_TEXT:
            "Are you sure you want to delete this brand? This cannot be undone.",
        DELETE_SUCCESS: "Successfully deleted brand '{name}'.",
        DELETE_FAILURE:
            "Failed to delete brand '{name}'. Please remove all associated products and users first.",
        LOGO: "Logo",
        LOGO_FILETYPES: "Allowed file types: png, jpg, jpeg",
        NAME: "Name",
        TAX_ID: "Tax ID",
        STREET: "Street",
        CITY: "City",
        ZIP: "ZIP",
        COUNTRY: "Country",
        PRODUCT_PAGE: "Product Page Feature",
        NO_LOGO: "None",
    },
    USER: {
        USER: "User",
        USERS: "Users",
        VIEW: "View User",
        EDIT: "Edit User",
        NEW: "New User",
        NOT_FOUND: "User not found",
        SHOW_COUNT: "No users | Showing 1 user | Showing {n} of {total} users",
        DELETE_TITLE: "Delete user '{name}'?",
        DELETE_TEXT:
            "Are you sure jou want to delete this user? This cannot be undone.",
        DELETE_SUCCESS: "Successfully deleted user '{name}'.",
        DELETE_FAILURE: "Failed to delete user '{name}'.",
        EMAIL: "Email",
        TITLE: "Title",
        FIRST_NAME: "First name",
        LAST_NAME: "Last name",
        FUNCTION: "Function",
    },
    PRODUCT: {
        PRODUCT: "Product",
        PRODUCTS: "Products",
        MANAGEMENT: "Product Management",
        VIEW: "View Product",
        EDIT: "Edit Product",
        NEW: "New Product",
        NOT_FOUND: "Product not found",
        SHOW_COUNT:
            "No products | Showing 1 product | Showing {n} of {total} products",
        DELETE_TITLE: "Delete Product '{name}'?",
        DELETE_TEXT:
            "Are you sure jou want to delete this product? This cannot be undone.",
        DELETE_SUCCESS: "Successfully deleted product '{name}'.",
        DELETE_FAILURE:
            "Failed to delete product '{name}'. Please remove all associated colors first.",
        NAME: "Name",
        BRAND_DIVISION: "Brand division",
        SKU: "SKU",
        CATEGORY: "Category",
        SUB_CATEGORY: "Subcategory",
        FURTHER_SUB_CATEGORY: "Further subcategory",
        GENDER: "Gender",
        FEDAS_PCK: "FEDAS PCK",
        APP_DESCRIPTION: "App description",
        INTERNAL_DESCRIPTION: "Internal description",
        FURTHER_INTERNAL_DESCRIPTION: "Further internal description",
        QRCODE: "QR-Code genaration",
        QRCHARGE: "QR-Code lists",
        SHOW_COUNT2:
            "no QR-CODE list | show 1 QR-Code list | show {n} of {total} QR-Code lists",
        QR_NAME: "Name",
        QR_ORDER_NUMBER: "Order Number",
        QRC_COMMENT: "Comment",
        QR_AMOUNT: "Amount of codes",
        QR_EXPORT: "Action",
        QR_CREATE: "created",
        QR_USED: "assigned",
        QR_MANUFACTURER: "Manufacturer",
        EXPORT: "Product Export",
        PRODUCT_PAGE: "Product Page",
        PRODUCT_PAGE_CUSTOM: "Custom URL",
        PRODUCT_PAGE_XIPHOO: "XIPHOO Website Builder",
        PRODUCT_PAGE_LABEL: "Custom URL",
        PRODUCT_PAGE_EDIT: "Edit Product Page",
    },
    COLOR: {
        COLOR: "Color",
        COLORS: "Colors",
        VIEW: "View Color",
        EDIT: "Edit Color",
        NEW: "New Color",
        NOT_FOUND: "Color not found",
        SHOW_COUNT:
            "No colors | Showing 1 color | Showing {n} of {total} colors",
        DELETE_TITLE: "Delete color '{name}'?",
        DELETE_TEXT:
            "Are you sure jou want to delete this color? This cannot be undone.",
        DELETE_SUCCESS: "Successfully deleted color '{name}'.",
        DELETE_FAILURE:
            "Failed to delete color '{name}'. Please remove all associated sizes first.",
        COMPONENTS_AND_LAUNDRY_INSTRUCTIONS:
            "Components & Laundry Instructions",
        IMAGE: "Image",
        IMAGE_FILETYPES: "Allowed file types: png, jpg, jpeg",
        NAME: "Name",
        WEIGHT: "Weight",
        YEAR: "Year",
        ORIGIN: "Country of Origin",
        CUSTOMS_NR: "Customs Tariff Number",
        URL: "App URL",
        NO_IMAGE: "None",
        CODE: "Code",
        TAGS: "Tags",
        SCANS: "Scans",
        IMPORT_PRODUCT_PAGE_LINK: "Import XIPHOO Product Page Link",
    },
    SIZE: {
        SIZE: "Size",
        SIZES: "Sizes",
        VIEW: "View Size",
        EDIT: "Edit Size",
        NEW: "New Size",
        NOT_FOUND: "Size not found",
        SHOW_COUNT: "No sizes | Showing 1 size | Showing {n} of {total} sizes",
        DELETE_TITLE: "Delete size '{name}'?",
        DELETE_TEXT:
            "Are you sure jou want to delete this size? This cannot be undone.",
        DELETE_SUCCESS: "Successfully deleted size '{name}'.",
        DELETE_FAILURE:
            "Failed to delete size '{name}'. Please remove all associated tags first.",
        NAME: "Name",
        EAN: "EAN",
        UPC: "UPC",
        RRP: "RRP",
        SCANS: "Scans",
        TAGS: "Tags",
    },
    LAUNDRY: {
        TITLE: "Laundry instructions",
        WASHING: "Washing",
        BLEACHING: "Bleaching",
        DRYING: "Drying",
        IRONING: "Ironing",
        PROFESSIONAL_CLEANING: "Professional cleaning",
    },
    COMPONENT: {
        COMPONENT: "Component",
        COMPONENTS: "Components",
        VIEW: "View Component",
        EDIT: "Edit Component",
        NEW: "New Component",
        NOT_FOUND: "Component not found",
        SHOW_COUNT:
            "No components | Showing 1 component | Showing {n} of {total} components",
        DELETE_TITLE: "Delete somponent '{name}'?",
        DELETE_TEXT:
            "Are you sure jou want to delete this component? This cannot be undone.",
        DELETE_SUCCESS: "Successfully deleted component '{name}'.",
        DELETE_FAILURE: "Failed to delete component '{name}'.",
        NAME: "Name",
        MATERIAL: "Material",
        MATERIALS: "Materials",
        AMOUNT: "Amount",
    },
    TAG: {
        TAG: "Tag",
        TAGS: "Tags",
        NOT_FOUND: "Tag not found",
        SHOW_COUNT: "No tags | Showing 1 tag | Showing {n} of {total} tags",
        DELETE_TITLE: "Delete tag '{name}'?",
        DELETE_TEXT:
            "Are you sure jou want to delete this tag? This cannot be undone.",
        DELETE_SUCCESS: "Successfully deleted tag '{name}'.",
        DELETE_FAILURE: "Failed to delete tag '{name}'.",
        DETAILS: "Tag Details",
        SCAN_DETAILS: "Scan Details",
        UID: "UID",
        SCANS: "Scans",
        LAST_SCAN: "Last Scan",
        LAST_SCAN_NEVER: "Never",
        LAST_LOCATION: "Last Location",
        LOCATION_UNKNOWN: "Unknown",
        HISTORY: {
            HISTORY: "Scan History",
            SHOW_COUNT:
                "No scans | Showing 1 scan | Showing {n} of {total} scans",
            TIME: "Time",
            SECURE_SCAN: "Secure Scan",
            LATITUDE: "Latitude",
            LONGITUDE: "Longitude",
        },
        STATUS: {
            STATUS: "Status",
            REGISTRATION_PENDING: "Registration pending",
            SCAN_PENDING: "Scan pending",
            OPERATIONAL: "Operational",
            INVALID: "Invalid",
        },
        SELLER: "Seller",
        LIFECYCLE: {
            LIFECYCLE: "Lifecycle",
            PRESALE: "Presale",
            POSTSALE: "Postsale",
        },
    },
    SEARCH: {
        SEARCH: "Search",
        PLACEHOLDER: "Product name, SKU, EAN, UPC ...",
        NO_RESULTS: "No Results",
    },
    VALIDATION: {
        REQUIRED: "{name} is required",
        ASCII: "{name} can only contain ASCII characters",
        DIGITS: "{name} can only contain digits",
        LENGTH_EXACT: "{name} must be exactly {length} characters long",
        LENGTH_MAX: "{name} has to be {length} characters long at max",
        WEB_URL: "{name} is not a valid web URL",
        EAN: "{name} is not a valid EAN-8 or EAN-13",
        PRICE: "{name} is not a valid price",
        EMAIL: "{name} is not a valid email address",
        PASSWORD:
            "{name} needs to have a minimum of 12 characters and contain at least one uppercase letter, one lowercase letter and one number",
        IDENTICAL: "{name} and {name_compare} have to be identical",
        EXISTS: "{name} already exists",
        PERCENTAGE: "{name} is not a valid percentage",
        LOW: "{name} must be greater than 0.",
    },
    QRCHARGE: {
        NEW: "New QR-Code list",
        ORDER_NUMBER: "Order Number",
        COMMENT: "Comment",
        AMOUNT: "Amount",
        EXPORTCSV: "Download CSV file",
        EDIT: "Edit QR-Code list",
        TITLE: "QR-Code",
        NAME: "Charge name",
        MANUFACTURER: "Manufacturer",
         NOMANUFACTURER: "no Manufacturer",
        DELETE_TITLE: "QR-list delete",
        DELETE_TEXT: "Do you really want to delete the QR list?",
    },
    MANUFACTURER: {
        MANUFACTURER: "Manufacturer",
        MANUFACTURERLIST: "Manufacturer list",
        NAME: "Name",
        STREET: "Street",
        CITY: "City",
        ZIP: "ZIP",
        COUNTRY: "Country",
        CODE: "Code",
        COMMENT: "Comment",
        NEW: "Add",
        SHOW_COUNT: "Count",
        VIEW: "Manufacturer",
        EDIT: "Edit Manufacturer",
        DELETE_TITLE: "Manufacturer delete",
        DELETE_TEXT: "Do you really want to delete the manufacturer?",
    },
    PRODUCT_PAGE: {
        TITLE: "Product Page Builder",
        SUBTITLE: "Site Components",
        PREVIEW: "Preview",
        EDIT: "Edit Site Component",
        VIEW: "Preview",
        NAME: "Name of Element / Title",
        DESCRIPTION: "Description",
        NOT_FOUND: "Product Page not found",
        COLOR_OPTION_NAME: "Product Information",
        BRAND_LOGO_NAME: "Brand Logo",
        BACKGROUND_NAME: "Background",
        MODEL: "Model",
        COLOR: "Color",
        SIZE: "Size",
        CANCEL: "Cancel",
        SAVE: "Save",
        FONT: "Font",
        FONT_PREVIEW: "Textpreview",
        NEW_COMPONENT: "Add Component",
        NEW_INFORMATION_COMPONENT: "Add Product Information",
        NEW_BRAND_LOGO: "Add Brand Logo",
        NEW_BACKGROUND: "Add Background",
        BACKGROUND_COLOR: "Background Color (HEX-Value)",
        POSITIONING: "Positioning",
        TEXTPOSITIONING: "Text Positioning",
        TEXT_COLOR: "Text Color (HEX-Value)",
        BORDER: "Border",
        BORDER_COLOR: "Border Color (HEX-Value)",
        WASHING_INSTRUCTION: "Washing Instruction",
    },
    PRODUCT_PAGES: {
        TITLE: "Product Pages",
        NEW: "New Product Page",
        NAME: "Name",
        PRODUCTS: "Related Products",
        NO_PRODUCTS: "No Related Products",
        EXPAND: "Related Products",
    },
    PRODUCT_PAGE_META: {
        TITLE: "Product Page Information",
        NEW: "New Product Page",
        EDIT: "Edit Product Page",
        VIEW: "View Product Page",
        NOT_FOUND: "Product Page not found",
        DELETE_TITLE: "Delete Product Page '{name}'?",
        DELETE_TEXT:
            "Are you sure you want to delete this product page? This cannot be undone.",
        DELETE_SUCCESS: "Successfully deleted product page '{name}'.",
        DELETE_FAILURE: "Failed to delete product page '{name}'.",
        NAME: "Name",
        PRODUCT_PAGE: "Edit Product Page",
        PRODUCT_RELATIONS: "Shared Product Page",
        NO_PRODUCTS: "There are no products associated with this product page.",
        ADD_PRODUCT: "Add Product to Product Page",
        SELECT_PRODUCT: "Select product...",
        ADD_PRODUCT_BUTTON: "Add Products",
    },
}
