import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const RESET_PASSWORD = "resetPassword";
export const SET_PASSWORD = "setPassword";
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_CHALLENGE = "setChallenge";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    challenge: {},
    user: {},
    isAuthenticated: !!JwtService.getToken(),
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    currentChallenge(state) {
        return state.challenge;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    getError(state) {
        return state.errors;
    },
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise((resolve) => {
            ApiService.post("login", credentials)
                .then(({data}) => {
                    if (data.challenge) {
                        context.commit(SET_CHALLENGE, data);
                        resolve(false);
                    } else {
                        context.commit(SET_AUTH, data);
                        resolve(true);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response);
                    resolve(false);
                });
        });
    },
    [LOGOUT](context) {
        context.commit(PURGE_AUTH);
    },
    [VERIFY_AUTH](context) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("verify")
                    .then(({data}) => {
                        context.commit(SET_AUTH, data);
                        resolve(true);
                    })
                    .catch(() => {
                        context.commit(PURGE_AUTH);
                        resolve(false);
                    });
            } else {
                context.commit(PURGE_AUTH);
                resolve(false);
            }
        });
    },
    [RESET_PASSWORD](context, email) {
        return new Promise((resolve) => {
            ApiService.post("forgotPassword", email)
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(false);
                });
        });
    },
    [SET_PASSWORD](context, data) {
        return new Promise((resolve) => {
            ApiService.post("setPassword", data)
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(false);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error.message;
    },
    [SET_CHALLENGE](state, challenge) {
        state.challenge = challenge;
    },
    [SET_AUTH](state, user) {
        state.isAuthenticated = true;
        state.user = user;
        state.errors = {};
        state.challenge = {};
        JwtService.saveToken(state.user.token);
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        state.challenge = {};
        JwtService.destroyToken();
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
